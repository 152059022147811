<template>
  <div>
    <subscription-modal
      :show="showSubscriptionModal"
      type="CAR"
      @close="closeModal"
    />
    <ca-header
      :heading="`Fahrzeuge ${numberOfCars}/${carLimit}`"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="numberOfCars < carLimit"
          class="btn btn-primary"
          :to="{name: 'CarCreate'}"
        >
          Neues Fahrzeug hinzufügen
        </router-link>
        <b-button
          v-if="numberOfCars >= carLimit"
          class="text-white"
          variant="success"
          @click="openSubscriptionModal"
        >
          Weitere Fahrzeuge dazubuchen
        </b-button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        :multiple-delete="true"
        service="car"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'CarDetail', params: {carId: item._id}})"
        @deleted="fetchData"
      >
        <template
          slot="status"
          slot-scope="{value: status, unformatted: statusCode}"
        >
          <span
            class="badge text-white"
            :class="getStatusClass(statusCode)"
          >{{ status }}</span>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import SubscriptionModal from '@/components/Modals/SubscriptionModal'
export default {
  name: 'CarList',
  components: {
    DataTable,
    CaHeader,
    SubscriptionModal
  },
  data: () => ({
    showSubscriptionModal: false,
    fields: [
      {
        label: 'Kennzeichen',
        key: 'licensePlate',
        filter: {
          type: 'SEARCH'
        }
      },
      {
        label: 'Erstellt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Kraftstoff',
        key: 'fuel',
        filter: {
          type: 'LIST',
          fetchOptions: () => ([
            { value: 'Benzin' },
            { value: 'Diesel' },
            { value: 'Elektro' },
            { value: 'Erdgas' },
            { value: 'LPG' }
          ])
        }
      }
    ],
    statusFilter: 1,
    numberOfCars: null,
    loading: false
  }),
  computed: {
    carLimit () {
      return this.me.subscriptions.reduce((carLimit, subscription) => {
        if (new Date(subscription.from) <= Date.now() && new Date(subscription.to) >= Date.now() && subscription.type === 'CAR') {
          carLimit += subscription.limit
        }
        return carLimit
      }, 0)
    },
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      const baseQuery = {
        userId: this.me._id
      }

      return baseQuery
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    closeModal () {
      this.showSubscriptionModal = false
    },
    openSubscriptionModal () {
      this.showSubscriptionModal = true
    },
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 0: statusClass = 'badge-default'; break
      case 1: statusClass = 'badge-success'; break
      case 2:
      case 3:
      case 4: statusClass = 'badge-warning'; break
      }
      return statusClass
    },
    setStatusFilter (statusCode) {
      this.statusFilter = statusCode
    },
    async fetchData () {
      try {
        this.loading = true
        this.numberOfCars = (await this.$store.dispatch('car/find', { query: { userId: this.me._id, $limit: 0 } })).total
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }

  }
}
</script>

<style scoped lang="scss">

</style>
